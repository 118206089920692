import * as styles from './CubeMap.module.scss'

import React, { useEffect, useMemo, useCallback, useState } from 'react'
import * as d3 from 'd3'

import { useWindowSize } from 'utils/useWindowSize'
import { usePersistentState } from 'utils/usePersistentState'
import { useRAFThrottledEffect } from 'utils/useRAFThrottledEffect'

import { expand2dRangeByFraction } from 'components/visualization/utils/ranges'

import { Layout } from 'components/maps/shared/Layout'
import { useSearchTree } from 'components/maps/shared/useSearchTree'
import { Transform } from 'components/maps/shared/transform'
import { squareExtent } from 'components/maps/shared/squareExtent'

import LoadingOverlay from 'components/loading/Overlay'
import ElementContainer from 'components/utils/ElementContainer'

import { buildProgram } from './rendering/buildProgram'
import { bindTextures } from './rendering/bindTextures'
import { bindVertexBuffer } from './rendering/bindVertexBuffer'
import { buildVertexArray } from './rendering/buildVertexArray'
import { draw } from './rendering/draw'

import { date } from './data/config'
import { useS3MapData, Cube, useClustersData } from './data/data'
import { useCubeSelection } from './data/useCubeSelection'
import { useCardSelection } from './data/useCardSelection'

import { SearchMode } from './search/SearchMode'

import Controls from './Controls'
import Attributions from './Attributions'
import ShareError from './ShareError'
import { Tooltips } from './tooltips/Tooltips'

const CubeMap: React.FC = () => {
  const { width, height } = useWindowSize(800, 800)

  // Load data

  const cubes = useS3MapData(date)
  const clusters = useClustersData(date)

  const [zoomTransform, setZoomTransform] = useState(d3.zoomIdentity)

  const canvasScale = useMemo(() => {
    return typeof window === 'undefined' ? 1 : window.devicePixelRatio
  }, [])

  const { canvas, programInfo } = useMemo(() => {
    const canvas = document.createElement('canvas')
    canvas.className = styles.canvas

    return { canvas, programInfo: buildProgram(canvas) }
  }, [])

  // Selection

  const [hoveredCube, setHoveredCube] = useState<Cube | null>(null)

  const [scaleByFollowers, setScaleByFollowers] = usePersistentState(
    'cube-map-scale-cubes-by-followers-2',
    true,
  )

  const [searchMode, setSearchMode] = usePersistentState<SearchMode>(
    'cube-map-search-mode',
    'cubes',
  )

  const setModeCubes = useCallback(
    () => setSearchMode('cubes'),
    [setSearchMode],
  )

  const setModeCards = useCallback(
    () => setSearchMode('cards'),
    [setSearchMode],
  )

  const cubeSelection = useCubeSelection(cubes, setModeCubes)
  const cardSelection = useCardSelection(date, setModeCards)

  const cubeIDsForCardSelection = useMemo(
    () => (searchMode === 'cards' ? cardSelection.cubeIDs : []),
    [cardSelection.cubeIDs, searchMode],
  )

  const extent = useMemo(
    () => expand2dRangeByFraction(squareExtent(cubes), 0.1),
    [cubes],
  )

  const points = useMemo(
    () =>
      buildVertexArray(
        cubes,
        cubeSelection.selected?.cluster ?? null,
        cubeSelection.showClusterInfo,
        cubeIDsForCardSelection,
        scaleByFollowers,
      ),
    [
      cubes,
      cubeSelection.selected,
      cubeSelection.showClusterInfo,
      cubeIDsForCardSelection,
      scaleByFollowers,
    ],
  )

  const { setSelected } = cubeSelection

  // Bind point buffer
  useEffect(() => {
    bindVertexBuffer(points, programInfo)
  }, [points, programInfo])

  // Bind Textures
  const textureSet =
    (cubeSelection.showClusterInfo && cubeSelection.selected != null) ||
    cubeIDsForCardSelection.length > 0
      ? 'selection'
      : 'standard'

  useEffect(() => {
    bindTextures(programInfo, textureSet)
  }, [programInfo, textureSet])

  // Draw
  useRAFThrottledEffect(() => {
    draw(
      programInfo,
      canvas,
      width,
      height,
      zoomTransform,
      points,
      extent,
      canvasScale,
    )
  }, [width, height, zoomTransform, points, extent, canvasScale])

  // Zoom

  const zoom = useMemo(() => {
    return d3
      .zoom<HTMLCanvasElement, unknown>()
      .scaleExtent([0.5, 10000])
      .on('zoom', (event: { transform: d3.ZoomTransform }) => {
        setZoomTransform(event.transform)
      })
  }, [])

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setSelected(null)
      }
    }
    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [setSelected])

  const defaultZoom = useMemo(() => {
    return cubeSelection.all.length > 1 && width >= 900
      ? d3.zoomIdentity.translate(width / 7, 0)
      : d3.zoomIdentity
  }, [cubeSelection.all.length, width])

  // Set up Zoom
  useEffect(() => {
    d3.select(canvas).call(zoom)
    d3.select(canvas).call(zoom.transform, defaultZoom)

    return () => {
      d3.select(canvas).on('.zoom', null)
    }

    // Disable hook since the 'defaultZoom' should not trigger re-setting up the
    // zoom behavior.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas, zoom])

  // Finding Cubes in 2d Space

  const itemAtPoint = useSearchTree(cubes, width, height, zoomTransform, extent)

  // Transform

  const transform = Transform.withDomainRange(extent, [
    [0, width],
    [0, height],
  ])
    .scaled(Math.min(height / width, 1), Math.min(width / height, 1))
    .translated(
      Math.max((width - height) / 2, 0),
      Math.max((height - width) / 2, 0),
    )
    .appending(new Transform(zoomTransform.x, zoomTransform.y, zoomTransform.k))

  // Events

  useEffect(() => {
    canvas.onmousemove = (event) => {
      setHoveredCube(itemAtPoint(event))
    }
  }, [canvas, itemAtPoint])

  useEffect(() => {
    canvas.onclick = (event) => {
      cubeSelection.setSelected(itemAtPoint(event))
    }
  }, [canvas, cubeSelection, itemAtPoint])

  useEffect(() => {
    canvas.onmouseleave = () => setHoveredCube(null)
  }, [canvas])

  const resetZoom = useCallback(() => {
    d3.select(canvas)
      .transition()
      .duration(250)
      .call(zoom.transform, defaultZoom)
  }, [canvas, zoom.transform, defaultZoom])

  const zoomToCube = useCallback(
    (item: { x: number; y: number }) => {
      const sTransform = Transform.withDomainRange(extent, [
        [0, width],
        [0, height],
      ])
        .scaled(Math.min(height / width, 1), Math.min(width / height, 1))
        .translated(
          Math.max((width - height) / 2, 0),
          Math.max((height - width) / 2, 0),
        )

      const point = sTransform.apply([item.x, item.y])

      const newZoom = d3.zoomIdentity
        .translate(width / 2, height / 2)
        .scale(22)
        .translate(-point[0], -point[1])

      d3.select(canvas).transition().duration(450).call(zoom.transform, newZoom)
    },
    [canvas, extent, height, width, zoom.transform],
  )

  return (
    <Layout>
      <ElementContainer>{canvas}</ElementContainer>

      {cubes.length === 0 && <LoadingOverlay />}

      <Tooltips
        cubeSelection={cubeSelection}
        hoveredCube={hoveredCube}
        transform={transform}
        zoomScale={zoomTransform.k}
      />

      <div className={styles.controls}>
        <Controls
          searchMode={searchMode}
          setSearchMode={setSearchMode}
          cubes={cubes}
          clusters={clusters}
          cubeSelection={cubeSelection}
          cardSelection={cardSelection}
          scaleByFollowers={scaleByFollowers}
          setScaleByFollowers={setScaleByFollowers}
          zoomToCube={zoomToCube}
        />
      </div>

      {zoomTransform !== defaultZoom && (
        <button
          type="button"
          onClick={resetZoom}
          className={styles.resetButton}
        >
          Reset Zoom
        </button>
      )}

      <Attributions />

      {cubeSelection.shareError && <ShareError cubeSelection={cubeSelection} />}
    </Layout>
  )
}

export default CubeMap
